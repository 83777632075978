<template>
   <div class="card vh-100 page_card ">
      <div class="card-header px-0 py-2">
        <div class="container">
          <SearchBar version="mini" />          
        </div>
      </div>
      <div class="card-body overflow-auto bg-transparent">
        <div class="d-flex justify-content-center">
          <nav aria-label="breadcrumb" class="text-center">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ name:'Landing' }">Home</router-link>
              </li>
              
              <li class="breadcrumb-item active" aria-current="page">Chapters</li>
            </ol>
          </nav>
        </div>
        <RecentChapter></RecentChapter>
        <div class="container mt-3">
          <div class="mb-5 text-center">
            <h1 class="headline_3 ">All Chapters</h1>
          </div>
          <div v-if="loading">
            <div class="row justify-content-md-center">
              <div class="col-12">
                <loading/>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row g-2 g-md-5">
              <div class="col-6 col-md-4 col-lg-3" v-for="item in fetchedData" :key="item">
                <router-link :to="'/chapter/details/' + item.chapter_number + '/' + titleToSlug(item.name_translated)" class="text-decoration-none card_page_wrapper">
                  <div class="card book_cover text-center h-100">
                    <div class="card-header bg-transparent border-0 p-0">
                      <img :src="bookHeader" class="img-fluid">
                    </div>
                    <div class="card-body">
                      <div class="card-number mb-2">{{ item.chapter_number }}</div>
                      <h5 class="h6">{{item.name}}</h5>
                      <p>{{ item.name_transliterated }}</p>
                      <p class="p-0 m-0">Sloks : {{ item.verses_count }}</p>
                    </div>
                    <div class="card-footer bg-transparent border-0 p-0">
                      <img :src="bookFooter" class="img-fluid">
                    </div>
                  </div>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<style>
</style>

<script>
import RecentChapter from '@/components/RecentChapter.vue';
import Logo from '@/assets/logo-square.png'
import BookHeader from '@/assets/book-header.png'
import BookFooter from '@/assets/book-footer.png'
import loading from '@/components/loader/SkeletonTwo.vue';
import SearchBar from '@/components/SearchBar.vue';


import { useHead } from "@vueuse/head";
import axios from 'axios';
export default {
    name: 'RihaalView',
    data() {
      return {
        logo: Logo,
        bookHeader: BookHeader,
        bookFooter: BookFooter,
        edition_name : this.$store.getters.getEdition,
        loading: true,
        fetchedData: [],
      };
    },
    setup() {      
      useHead({
          title: 'Chapters of Gita - My Gita GPT',
          meta: [
            {
              name: 'description',
              content: 'Read the Bhagavad Gita online with profound and easy-to-understand by My Gita GPT',
            },
          ],
      })
    },  
    methods: {
      titleToSlug(title) {
        return title.toLowerCase().replace(/[^\w ]+/g,'').replace(/ +/g,'-');
      },
      async fetchData() {
        this.loading = true;
        try {
          let url = `${process.env.VUE_APP_ROOT_API_DOMAIN}/gita/chapters/`
          const options = {
            method: 'GET',
            url: url
          };

          const response = await axios(options);
          this.fetchedData = response.data.data;
          if((response.data.data).length > 0)
          {
            this.loading = false;
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    },
    created()
    {
      this.fetchData()
    },
    components: {
      SearchBar,
      loading,
      RecentChapter,
    }
}
</script>
