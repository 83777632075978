import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import PrimeVue from 'primevue/config';
import 'primevue/resources/themes/lara-light-indigo/theme.css';
import 'primevue/resources/primevue.min.css';

// env
// import dotenv from 'dotenv';
// dotenv.config();
// env


// sweet alert
import VueSweetalert2 from 'vue-sweetalert2'; 

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';
// sweet alert


// meta tags manager
import { createHead } from '@vueuse/head'

import './registerServiceWorker'
const useHead = createHead()
// meta tags manager


// Get the stored data from local storage
const storedData = localStorage.getItem('mygitagptstorageunit');
if (storedData) {
  store.replaceState(JSON.parse(storedData));
}


// non www to www redirect
router.beforeEach((to, from, next) => {
  // Continue with the navigation
  // alert(to.fullPath);
  // alert(to.hostname);
  // alert(location.host);
  // alert(location.toString());
  if(!/\.?www./g.test(location.toString())) {
    location.href = location.href.replace("://","://www.")
  }
  next();
});


const app = createApp(App)
app.use(store)
app.use(useHead)
app.use(router)
app.use(PrimeVue);
app.use(VueSweetalert2);

// google login
import vue3GoogleLogin from 'vue3-google-login'
app.use(vue3GoogleLogin, {
  clientId: '521856797844-evvvis2cmjrkibls2c0p7u96r3m0nett.apps.googleusercontent.com'
})
// google login

app.mount('#app')
