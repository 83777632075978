<template>
    <center>
      <GoogleLogin :callback="callback" class="w-100" ></GoogleLogin>
    </center>
  </template>
  
  <script>
  import swal from 'sweetalert';
  import { defineComponent } from 'vue';
  import { decodeCredential } from 'vue3-google-login';
  import axios from 'axios';

  
  export default defineComponent({
    data() {
      return {
        registerUser : {
            full_name: '',
            email: '',
            avatar: '',
            social_platform: 'google',
            social_operator_id: '',
            register_error: false,
        }
      };
    },
    methods: {
      callback(response) {
        // This callback will be triggered when the user selects or logs in to
        // their Google account from the popup
        console.log("Handle the response", response);
        const userData = decodeCredential(response.credential);
        console.log("Handle the userData", userData);
        

        this.registerUser.full_name = userData.name
        this.registerUser.email = userData.email
        this.registerUser.social_operator_id = userData.sub
        this.registerUser.avatar = userData.picture

        this.googleAuthentication()

      },

      googleAuthentication()
      {
        // calling api
        const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/continue-with-social/`;
        const formData = axios.toFormData(this.registerUser)

        axios.post(API_URL, formData)
        .then((response) => {
          console.log(response,'response');
          if(response.status == 203)
          {
            swal({
              title: "error",
              text: response.data.message,
              icon: "error",
            }); 
            
          }
          else if(response.status == 200)
          {
            swal({
              title: "success",
              text: response.data.message,
              icon: "success",
            });  
            this.$store.commit('SET_USER', response.data.data.user)
            this.$store.commit('SET_TOKEN', response.data.data.token)
            this.$store.commit('SET_AUTHENTICATED', true)
            this.$store.commit('SET_USER_AVATAR', response.data.data.user.avatar)
          }
          this.loading = false;
          location.reload()
          // this.$router.push({ name: 'DashboardScreen' })
        })
        // calling api
      }
    },
  });
  </script>
  