<template>
  <div class="wrapper-container">
      <section class="call_to_action">
        <div class="container">        
          <div class="row align-items-center justify-content-center vh-100">
            <!-- <div class="col-8 text-center col-md-6 text-body-secondary d-none d-md-block">
                <img :src="phones" class="img-fluid">
            </div> -->
            <div class="col-12 text-center col-md-6 text-body-secondary ">
                <p class="text-body-secondary p-0 m-0 fw-bold">Download My Gita GPT to Use All Features</p>
                <div class="row justify-content-center">
                    <div class="col-10 col-md-8">
                        <a href="https://www.gitagpt.ai/"><img :src="logo" class="img-fluid my-2"></a>
                        <div class="d-flex gap-4 flex-column justify-content-center mt-3">
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.gitagpt.application&pli=1"><img :src="google_play_store" class="img-fluid"></a>
                            <a target="_blank" href="https://apps.apple.com/ae/app/gita-gpt/id6478273332"><img :src="apple_app_store" class="img-fluid"></a>
                            <a href="https://www.gitagpt.ai/" class="text-decoration-none h2">gitagpt.ai</a>
                        </div>                        
                    </div>
                </div>                
            </div>
            
            <!-- Enroll for the lucky winner -->
            <!-- ramadan offer for free unlimited service -->
          </div>
        </div>
      </section>
      <div class="bg-white">
        <FooterVue download_icons="false"></FooterVue>
      </div>
  </div>
</template>

<script>
import Logo from '@/assets/logo.png'
 import google_play_image from "@/assets/appimg/Googleplay.png"
import apple_app_image from "@/assets/appimg/appstore.png"
 
import FooterVue from '@/components/Footer.vue';

// import Swal from 'sweetalert2'
import swal from 'sweetalert';
import { useHead } from "@vueuse/head";
// import axios from 'axios';
export default {
    name: 'RihaalView',
    data() {
      return {
        logo: Logo,
        // qibla: qibla,
        google_play_store : google_play_image,
        apple_app_store : apple_app_image,
        // phones : phones,
        // qr_code : qr_code,
        edition_name : this.$store.getters.getEdition,
        loading: true
      };
    },
    setup() {      
      useHead({
          title: 'Download Our Mobile Application - My Gita GPT',
      })
    },  
    created() {
      if (this.isMobile()) {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        if (/android/i.test(userAgent)) {
          // Redirect to Google Play Store
          window.location.href = 'https://play.google.com/store/apps/details?id=com.gitagpt.application&pli=1';
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          // Redirect to Apple App Store
          window.location.href = 'https://apps.apple.com/ae/app/gita-gpt/id6478273332';
          this.redirectToAppleAppStore();
        }
      }
      else
      {
        swal({
          title: 'Download Our Mobile App Today!',
          text: 'Experience all the incredible features of My Gita GPT by downloading our mobile app.',
          icon: "info",
        }).then(function(){
          window.location.href = "/"
        }); 
      }
    },
    mounted() {
    },
    components: {
      FooterVue
    },
    methods: {
      isMobile() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      },
      redirectToAppleAppStore() {
        const appStoreLink = 'https://apps.apple.com/ae/app/gita-gpt/id6478273332';
        const anchor = document.createElement('a');
        anchor.setAttribute('href', appStoreLink);
        anchor.setAttribute('target', '_blank');
        anchor.click();
      }
    }
}
</script>

<style scoped>
*
{
  overflow: hidden;
}
.wrapper-container
{
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: white;
  z-index: 10000;
}
</style>