<template>
  <div @scroll="handleScroll">
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>

    <!-- <router-view/> -->

    <!-- Modal -->
    <div class="modal fade" id="authModal" data-bs-keyboard="false" tabindex="-1" aria-labelledby="authModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div class="modal-content">
          <div class="modal-header border-0 pb-0">
            <!-- Close button with Bootstrap attributes -->
            <button type="button" class="btn-close" ref="closeButton" data-bs-dismiss="modal"
              aria-label="Close"></button>
          </div>
          <div class="modal-body p-5">
            <!-- Auth component to be displayed in modal body -->
            <Auth />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useHead } from "@vueuse/head";
import { ref, watch, onMounted } from "vue";
import { useRoute } from "vue-router";
import Auth from "./views/auth/AuthView.vue";
import Logo from "@/assets/logo-square.png";
import BGVideo from "@/assets/bg.mp4";
import BGVideoCover from "@/assets/video-cover.jpg";

export default {
  name: "MyGitaGPT",
  components: {
    Auth,
  },
  setup() {
    const route = useRoute();
    const closeButton = ref(null);

    useHead({
      title: "Welcome to My Gita GPT - The encyclopedia of life",
      meta: [
        {
          name: "description",
          content:
            "The Bhagavad Gita is a profoundly spiritual scripture on various aspects of life, duty and spirituality. This Prince is a dialogue between Arjuna and Lord Krishna, where Krishna as Arjuna's charioteer offers guidance and wisdom to get through Arjuna's moral dilemma before the great battle.",
        },
      ],
    });

    watch(
      () => route.path,
      (newPath) => {
        if (newPath === "/forgot-password") {
          closeButton.value?.click();
        }
      }
    );

    onMounted(() => {
      console.log(process.env.VUE_APP_ROOT_API_DOMAIN);
    });

    return {
      closeButton,
    };
  },
  data() {
    return {
      menuToggle: false,
      BGVideo,
      BGVideoCover,
      logo: Logo,
      isSticky: false,
    };
  },
  methods: {
    handleScroll(event) {
      const scrollPosition = event.target.scrollTop;
      const threshold = 100;
      this.isSticky = scrollPosition >= threshold;
    },
    logout() {
      this.$store.dispatch("logout");
      location.reload();
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Michroma&display=swap");

/* font family arabic */

/* font family arabic */

.mvh-100 {
  min-height: 100vh;
  height: auto;
  max-height: auto;
}

body {
  background: url("../src/assets/banner.png") center fixed;
  background-size: cover;
}

#app {
  position: relative;
}

/* #app video 
  {
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
    object-fit: cover;
    z-index: -2;

  } */

#app::before {
  content: "";
  position: fixed;
  height: 300px;
  width: 300px;
  left: 0;
  top: 0;
  z-index: -1;
  background: url("../src/assets/krsishna_chakra.png");
  background-size: contain;
  animation: rotate_k_chakra 10s linear infinite;
}

@keyframes rotate_k_chakra {
  to {
    transform: rotate(360deg);
  }
}

#drop_menu_box {
  background: white;
  min-height: 100px;
  max-width: 200px;
  position: absolute;
  right: 20px;
  top: 60px;
  border-radius: 10px;
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

#drop_menu_box a {
  cursor: pointer;
}

.navbar {
  z-index: 10;
}

/* #app
  {
    height: 100vh;
    overflow: auto;
  } */
.sticky-class {
  background-color: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* Additional styling for sticky state */
}

/* body 
  {
    background: url('assets/bg.jpg') fixed center no-repeat;
    background-size:cover !important;
    animation: color 20s infinite linear;
  } */
/* @keyframes color {
    0%   {
      background: url('assets/bg.jpg') fixed center no-repeat;
    }
    50%   {
      background: url('assets/bg.jpg') fixed bottom no-repeat;
    }
    100%   {
      background: url('assets/bg.jpg') fixed center no-repeat;
    }
  } */
.text-link {
  color: #d52527 !important;
  text-decoration: none;
}

.wrapper_bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0), #fff, #fff, #fff);
}

.vh-100 {
  min-height: 100vh !important;
  height: auto !important;
  max-height: auto !important;
}

/* headline */
.headline_1 {
  color: #d52527;
  font-family: Michroma;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
}

.headline_1 span {
  background: linear-gradient(181deg, #a26501 26.11%, #eebe3a 68.68%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Michroma;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
}

@media only screen and (max-width: 600px) {
  .headline_1 {
    font-size: 30px !important;
    font-weight: 400;
    line-height: 50px;
  }

  .headline_1 span {
    font-size: 30px;
    font-weight: 400;
    line-height: 50px;
  }
}

/* headline */

/* popup */
.offcanvas.offcanvas-bottom {
  height: auto;
}

/* popup */

/* custom card */
.page_card {
  padding: 0 !important;
  margin: 0 !important;
  position: fixed;
  height: 100% !important;
  width: 100% !important;
  left: 0;
  top: 0;
  z-index: 100;
  background: url("../src/assets/banner-2.png") center center !important;
  background-size: cover !important;
}

.page_card .card-header {
  /* background: url("../src/assets/bg-Gita.png") center; */
  background-size: contain;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.page_card .card-footer {
  /* background: url("../src/assets/bg (1).jpg") center; */
  background-size: auto;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.cover_card {
  border-radius: 6px;
  border: 1px solid #e3b232;
  background: #f8f8f8;
  box-shadow: 0px 4px 20px 0px rgba(233, 178, 28, 0.31);
  padding: 30px;
  position: relative;
}

.cover_card:hover {
  border-radius: 6px;
  border: 1px solid #d52527;
  box-shadow: 0px 4px 20px 0px rgba(233, 178, 28, 0.31);
  padding: 30px;
  position: relative;
}

.cover_card::before {
  content: "";
  position: absolute;
  height: 100px;
  width: 100px;
  background: url("assets/top.svg") no-repeat right top;
  background-size: contain;
  top: 0;
  right: 0;
  display: block;
  z-index: 1 !important;
}

.cover_card::after {
  content: "";
  position: absolute;
  height: 100px;
  width: 100px;
  background: url("assets/bottom.svg") no-repeat bottom left;
  background-size: contain;
  bottom: 0;
  left: 0;
  display: block;
  z-index: 1 !important;
}

.cover_card .card-body {
  border: 1.5px solid #d3b358;
}

.card-number {
  width: 50px;
  height: 50px;
  font-size: 30px;
  font-style: normal;
  flex-shrink: 0;
  background: #d52527;
  display: block !important;
  margin: auto;
  line-height: 50px;
  color: white;
  border-radius: 50%;
  margin-bottom: 30px;
}

.card-number-2 {
  width: 50px;
  height: 50px;
  font-size: 30px;
  font-style: normal;
  flex-shrink: 0;
  background: linear-gradient(181deg,
      #d52527 2.46%,
      #d4a11d 53.41%,
      #f8be1b 95.48%);
  display: block !important;
  margin: auto;
  line-height: 50px;
  color: white;
  border-radius: 50%;
}

.cover_card h1 {
  color: #353535;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  /* 111.111% */
}

.cover_card h2 {
  color: #898989;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

.cover_card p {
  color: #ba9527;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 125% */
}

.book_cover {
  background: #a36501 url("../src/assets/book_bg.png");
  border-radius: 20px 10px 10px 20px;
  /* padding-bottom: 50px; */
  /* padding-left: 10px; */
  padding: 10px 10px 50px 10px;
  /* background-image: linear-gradient(90deg,#a36501 20px,#a36501 0,transparent 0); */
  position: relative;
  border: 0;
  color: #fff;
  transition-duration: 0.5s;
}

.book_cover:hover {
  background: #a36501 url("../src/assets/book_bg_hover.png");
  transform: scale(1.1);
}

.book_cover:after {
  box-sizing: inherit;
  content: "";
  position: absolute;
  height: 30px;
  width: calc(100% - 5px);
  bottom: 6px;
  right: 0;
  background: #fff;
  border-radius: 40px 4px 4px 40px;
  box-shadow: inset 4px 6px 0 0 #e4e0ce;
  background-image: linear-gradient(180deg,
      transparent 6px,
      #e4e0ce 8px,
      transparent 0,
      transparent 12px,
      #e4e0ce 0,
      transparent 14px,
      transparent 18px,
      #e4e0ce 0,
      transparent 20px,
      transparent 24px,
      #e4e0ce 0,
      transparent 26px,
      transparent 30px,
      #e4e0ce 0,
      transparent 32px,
      transparent 36px,
      #e4e0ce 0,
      transparent 38px,
      transparent 42px,
      #e4e0ce 0,
      transparent 44px,
      transparent 48px,
      #e4e0ce 0,
      transparent 50px);
}

.book_cover:before {
  box-sizing: inherit;
  content: "";
  position: absolute;
  height: 20px;
  width: 20px;
  bottom: 6px;
  left: 30px;
  background: url("../src/assets/bookmark-Vector.png") no-repeat;
  background-size: contain;
  z-index: 100;
}

.book_cover .card-body {
  background: url("../src/assets/book-body-bg.png");
  background-size: contain;
}

.book_cover h4 {
  color: #fff;
  font-size: 13px;
}

.book_cover h2 {
  color: #fff;
  font-size: 22px;
}

.book_cover h3 {
  color: #fff;
  font-size: 13px;
}

.book_cover p {
  color: #dadada;
  font-size: 11px;
}

@media only screen and (max-width: 600px) {
  .cover_card::before {
    content: "";
    position: absolute;
    height: 30px;
    width: 30px;
    background: url("assets/top.svg") no-repeat right top;
    background-size: contain;
    top: 0;
    right: 0;
    display: block;
    z-index: 1 !important;
  }

  .cover_card::after {
    content: "";
    position: absolute;
    height: 30px;
    width: 30px;
    background: url("assets/bottom.svg") no-repeat bottom left;
    background-size: contain;
    bottom: 0;
    left: 0;
    display: block;
    z-index: 1 !important;
  }
}

/* headline */
.headline_1 {
  color: #d52527;
  font-family: Michroma;
  font-size: 70px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
}

.headline_1 span {
  background: linear-gradient(181deg, #a26501 26.11%, #eebe3a 68.68%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Michroma;
  font-size: 80px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
}

.headline_3 {
  color: #d52527;
  font-family: Michroma;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 50px;
}

@media only screen and (max-width: 600px) {
  .headline_1 {
    font-size: 30px !important;
    font-weight: 400;
    line-height: 50px;
  }

  .headline_1 span {
    font-size: 30px;
    font-weight: 400;
    line-height: 50px;
  }

  .headline_3 {
    color: #d52527;
    font-family: Michroma;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }
}

/* headline */
#searchWrapper {
  position: relative;
}

#searchResultsArea {
  height: 80vh;
  max-width: 80vw;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  z-index: 100;
  border-radius: 10px !important;
  padding: 20px;
  position: fixed;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media only screen and (max-width: 600px) {
  #searchResultsArea {
    height: 100%;
    max-width: 100%;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    padding: 0px;
  }
}

#searchResultsArea .closeButton {
  width: 60px;
  height: 60px;
  line-height: 60px;
  flex-shrink: 0;
  border-radius: 50%;
  background: red;
  color: white;
  display: block;
  position: absolute;
  top: -30px;
  right: -30px;
}

#searchWrapper .search_input {
  /* border-radius: 90px;
    border: 0.3px solid #EFBF3B;
    background: rgba(239, 191, 59, 0.8);
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.05);
    padding: 15px;
    color: white !important;
    letter-spacing: 1px;
    font-size: 15px;
    font-weight: 600; */
  border-radius: 90px;
  border: 0.5px solid #ae3e30;
  background: rgba(244, 167, 91, 0.17);
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.05);
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 60px;
  /* 300% */
  padding: 0 30px;
}

#searchWrapper .search_input input:focus,
#searchWrapper .search_input input:active {
  border: none !important;
  outline: none;
}

#searchWrapper .search_button {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 50%;
  background: #d52527;
  color: white;
}

/* buttons */
.button {
  padding: 20px 70px;
  align-items: center;
  border-radius: 30px;
  border: 1px solid #8c2f23;
  background: linear-gradient(180deg, #8c2f23 0%, #db3706 100%);
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  display: inline-block;
  cursor: pointer;
  width: max-content;
}

.button_square {
  padding: 0px !important;
  height: 40px !important;
  width: 40px !important;
  line-height: 30px !important;
  border-radius: 10px;
  font-size: 30px;
  display: block;
  text-align: center;
  margin: 0 !important;
}

/* .button.button_outline
  {
    padding: 20px 100px;
    align-items: center;
    border-radius: 30px;
    border-radius: 30px;
    border: 1px solid #EFBF3B;
    background: white;
  } */

.button.router-link-active {
  padding: 20px 100px;
  align-items: center;
  border-radius: 30px;
  border-radius: 30px;
  border: 1px solid #db3706;
  background: white;
  color: #000;
}

.button:hover {
  padding: 20px 70px;
  align-items: center;
  border-radius: 30px;
  border-radius: 30px;
  border: 1px solid #db3706;
  background: white;
  color: #000;
}

.btn.btn.btn-warning {
  background: linear-gradient(180deg, #8c2f23 0%, #db3706 100%);
}

@media only screen and (max-width: 600px) {
  .button {
    padding: 5px 20px !important;
  }

  .button_square {
    padding: 0px !important;
    height: 40px !important;
    width: 40px !important;
    border-radius: 10px;
    font-size: 30px;
    display: block;
    text-align: center;
    margin: 0 !important;
  }
}

/* buttons */

/* image */
.max-width-100 {
  max-width: 100%;
}

/* cards */
.ayat_card {
  border-radius: 5px;
  /* padding: 50px 20px 10px 10px; */
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  position: relative;
  margin-bottom: 30px;
}

.ayat_card .card-header {
  min-height: 30px;
  margin-bottom: 10px;
  font-weight: bold;
  border-bottom: 5px solid rgba(140, 47, 35, 0.774);
}

.ayat_card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.519);
  /* Adjust opacity here */
  z-index: -1;
  /* Place the pseudo-element behind the content */
}

.ayat_card .card-title {
  line-height: 30px !important;
}

@media only screen and (max-width: 600px) {
  .ayat_card .card-title {
    line-height: 30px !important;
  }
}

/* .ayat_card::before{
    content: "";
    background: url("../src/assets/header-bg.png") center;
    background-size:contain;
    background-repeat: repeat-x;
    height: 30px;
    width: 100%;
    top: 0px;
    right: 0px;
    position: absolute;
  } */
/* .ayat_card::after{
    content: "";
    background: url("../src/assets/footer-bg.png") center;
    background-size:contain;
    background-repeat: repeat-x;
    height: 50px;
    width: 100%;
    bottom: 0px;
    right: 0px;
    position: absolute;
  } */

/* breadcrumb */
.breadcrumb-item a {
  color: #d52527;
  text-decoration: none;
}

/* krishna image */
.krishnaImage {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  /* Here we declare our inline animation */
  animation: rotate 3s linear infinite;
}

/* Here we define our animation with the name "rotate" */
@keyframes rotate {
  0% {
    box-shadow: 0px 0px 0px;
  }

  50% {
    box-shadow: 1px 1px 50px;
  }

  100% {
    box-shadow: 0px 0px 0px;
  }
}

/* krishna image */

.bg-warning {
  border-radius: 100px;
  background: linear-gradient(180deg, #8c2f23 0%, #db3706 100%);
}
</style>
