import { createRouter, createWebHistory } from 'vue-router'
import Landing from '../views/Landing.vue'
import Chapter from '@/views/Chapter.vue'
import ChapterDetailsView from '@/views/ChapterDetailsView.vue'
import DownloadAppPage from '@/views/DownloadAppPage.vue'

import NotFound from "@/views/NotFound.vue";

import ForgotPasswordView from '@/views/auth/ForgotPassword.vue'

// information pages
import AboutUsVue from '@/views/AboutUs.vue'
import ContactUsVue from '@/views/ContactUs.vue'
import PrivacyPolicy from '@/views/PrivacyPolicy.vue'
import TermsAndConditions from '@/views/TermsAndConditions.vue'
// information pages

const routes = [
  {
    path: '/',
    name: 'Landing',
    component: Landing
  },
  {
    path: '/about',
    name: 'AboutUsVue',
    component: AboutUsVue
  },
  {
    path: '/contact',
    name: 'ContactUsVue',
    component: ContactUsVue
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions
  }, 

  {
    path: '/forgot-password',
    name: 'ForgotPasswordView',
    component: ForgotPasswordView
  },
  {
    path: '/chapter',
    name: 'Chapter',
    component: Chapter
  },  
  {
    path: '/chapter/details/:chapter_id/:chapter_name',
    name: 'ChapterDetailsView',
    component: ChapterDetailsView,
    props: true 
  },
  {
    path: "/:pathMatch(.*)",
    component: NotFound,
  },
  {
    path: '/download',
    name: 'DownloadAppPage',
    component: DownloadAppPage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
