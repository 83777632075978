<template>
    <div v-if="version == 'mini'">
      <div role="search" id="searchWrapper" class="m-0 p-0">
        <!-- <form @submit.prevent="fetchStreamedText" class="d-flex gap-1 align-items-center">
          <input class="form-control search_input" required type="search" placeholder="Search" aria-label="Search" v-model="question">
          <button class="btn btn-light search_button" type="submit"><i class="bi bi-search"></i></button>
        </form> -->
        <form @submit.prevent="fetchStreamedText('S')" class="d-flex gap-1 align-items-center search_input p-0 overflow-hidden">
          <!-- <img :src=logo class="mb-0 py-0" height="50"> -->
          <input list="suggetions" class="w-100 border-0 bg-transparent px-4" required type="search" placeholder="Search" aria-label="Search" v-model="question" @keyup="getKeywordSuggestions()" autofocus>
          <button class="btn btn-light search_button" type="submit"><i class="bi bi-search"></i></button>
        </form>
        <datalist id="suggetions"><option :value="suggestion" v-for="suggestion in search_keyword_suggesion" :key="suggestion">{{suggestion}}</option></datalist>
        <div v-if="loading">
          <p class="placeholder-glow">
            <span class="placeholder col-12"></span>
            <span class="placeholder col-12"></span>
          </p>
          <p class="placeholder-glow">
            <span class="placeholder col-12"></span>
            <span class="placeholder col-12"></span>
          </p>
        </div>
        <div v-if="answer != ''">
          <div class="mb-2 searchResultWrapper" >
            <div class="container">
              <div class="card shadow">
                <div class="card-header">
                  <div class="d-flex">
                    <h4>Generated By Gita GPT</h4>
                    <a class="searchWindowClose btn" @click="answer = ''">
                      <i class="bi bi-x-square-fill"></i>
                    </a>
                  </div>
                </div>
                <div class="card-body overflow-auto">
                  <!-- <div class="text-body-tertiary mb-0" v-html="answer.replace(/\n/g, '<br />')"></div> -->
                  <div class="text-body-tertiary mb-0">
                      <p v-html="answer.replace(/\n/g, '<br />')"></p>
                    </div>
                    <div v-if="gpt_task" class="text-center">
                      <!-- <small class="d-block text-center">If the response provided doesn't meet your expectations, please feel free to request a regeneration </small> -->
                      <button class="button p-2 px-3 my-3" @click="fetchStreamedText('R')"><i class="bi bi-arrow-clockwise"></i> Re-Generate</button>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row justify-content-center">
        <div class="col-6 col-md-3">
          <img :src=LargLogo class="mb-0 py-0 img-fluid">
        </div>
      </div>
      <div role="search" id="searchWrapper" class="mt-5">
        <form @submit.prevent="fetchStreamedText('S')" class="d-flex gap-1 align-items-center search_input p-0 overflow-hidden">
          <!-- <img :src=logo class="mb-0 py-0" height="50"> -->
          <input list="suggetions" class="w-100 border-0 bg-transparent px-4" required type="search" placeholder="Search" aria-label="Search" v-model="question" @keyup="getKeywordSuggestions()" autofocus>
          <button class="btn btn-light search_button" type="submit"><i class="bi bi-search"></i></button>
        </form>
        <datalist id="suggetions"><option :value="suggestion" v-for="suggestion in search_keyword_suggesion" :key="suggestion">{{suggestion}}</option></datalist>
          <div v-if="loading">
            <p class="placeholder-glow">
              <span class="placeholder col-12"></span>
              <span class="placeholder col-12"></span>
            </p>
            <p class="placeholder-glow">
              <span class="placeholder col-12"></span>
              <span class="placeholder col-12"></span>
            </p>
          </div>
          <div v-if="answer != ''">
            <div class="mb-2" >
                <div class="card card-body text-start my-3">
                    <div class="text-body-tertiary mb-0">
                      <p v-html="answer.replace(/\n/g, '<br />')"></p>
                    </div>
                    <div v-if="gpt_task" class="text-center">
                      <!-- <small class="d-block text-center">If the response provided doesn't meet your expectations, please feel free to request a regeneration </small> -->
                      <button class="button p-2 px-3 my-3" @click="fetchStreamedText('R')"><i class="bi bi-arrow-clockwise"></i> Re-Generate</button>
                    </div>
                </div>
            </div>
          </div>   
        <p class="py-3">Free Research Preview. My Gita GPT may produce inaccurate information about people, places, or facts. </p>   
      </div>
      <div class="d-flex flex-column flex-md-row gap-3 justify-content-center">
        <router-link class="button" :to="{ name:'Chapter' }">Chapters of Gita</router-link>
      </div>
      <div class="nav d-flex mt-5 col-md-12 justify-content-center align-items-center justify-content-center">
          <div class="text-capitalize p-2 nav-item ms-2">
            <a class="a" href="https://play.google.com/store/apps/details?id=com.gitagpt.application" target="_blank" rel="noopener noreferrer">
              <img width="180" :src=playstore class="mb-0 py-0">
            </a>
          </div>
          <div class="text-capitalize p-2 nav-item ms-2">
            <a class="a" href="https://apps.apple.com/ae/app/gita-gpt/id6478273332" rel="noopener noreferrer">
              <img width="180" :src=appstore class="mb-0 py-0">
            </a>
          </div>
        </div>
    </div>
  </template>
  
  <script>
  import LargLogo from '@/assets/logo.png'
  import Logo from '@/assets/krishna.png'
  import playstore from '@/assets/appimg/Googleplay.png'
  import appstore from '@/assets/appimg/appstore.png'
  import axios from 'axios';


  export default {
    name: 'SearchBar',
    props: ['version'],
    data() {
      return {
        logo: Logo,
        playstore: playstore,
        appstore:appstore,
        LargLogo: LargLogo,
        loading : false,
        question: "",
        answer: "",
        gpt_task: false,
        search_keyword_suggesion: [],
      };
    },
    methods: {
      async getKeywordSuggestions()
      {
          if(this.question != "")
          {
            const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/gpt/search/suggestions/${this.question}/`;
            await axios.get(API_URL)
              .then((response) => {
                  if(response.status == 204)
                  {
                      // this.$toast.error(response.data.message, {
                      //     icon: "dangerous",
                      // }); 
                  }
                  else if(response.status == 200)
                  {
                      this.search_keyword_suggesion = response.data.suggestion
                  }

              })
              .catch((error) => {
              // Handle errors
                  console.log(error);
                  this.$toast.error("Ops something is wrong. try again later", {
                      icon: "dangerous",
                  }); 
                  this.loading = false;
              });
          }
      },
      async fetchStreamedText(type)
      {
          this.answer = "";
          this.loading = true
          this.gpt_task = false
          let streamUrl = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/gpt/campaign?prompt=${this.question}`;

          if(type == "R")
          {
            streamUrl += "&gentype=regenerate"
          }
          

          await fetch(streamUrl).then(response => {
              if (!response.ok) {
                  throw new Error('Network response was not ok');
              }
              this.loading = false
              const reader = response.body.getReader();
              const streamDecoder = new TextDecoder();
              const processStream = ({ done, value }) => {
                  if (done) {
                      console.log('Stream complete');
                      this.gpt_task = true;
                      return;
                  }
                  const chunk = streamDecoder.decode(value, { stream: true });
                  this.answer += chunk;
                  return reader.read().then(processStream);
              };
              return reader.read().then(processStream);
          }).catch(error => {
                  console.error('Fetch failed:', error);
          });
      },
      searchAnswer()
      {
          if(this.question != "")
          {
            this.answer = '';
            this.loading = true;

            // calling api
            const API_URL = `${process.env.VUE_APP_ROOT_API_ENDPOINT}/user/search/`;
            const formData = axios.toFormData({
                'question' : this.question
            })
            
            axios.post(API_URL, formData)
            .then((response) => {
                if(response.status == 204)
                {
                    this.$toast.error(response.data.message, {
                        icon: "dangerous",
                    }); 
                }
                else if(response.status == 200)
                {
                    // this.getConversations()
                    this.answer = response.data.data.response_gpt;
                }
                console.log(response.data);
                this.loading = false;
            })
            .catch((error) => {
            // Handle errors
                console.log(error);
                this.$toast.error("Ops something is wrong. try again later", {
                    icon: "dangerous",
                }); 
                this.loading = false;
            }); 
          }
          // calling api
      },

      searching()
      {
          if(this.question != undefined)
          {
              this.loading = true;
              this.searchAnswer();
          }
      },
    },
  };
  </script>
  

<style >
.searchResultWrapper
{
  position: fixed;
  max-height: 80vh;
  left: 0;
  right: 0;
  z-index: 10000000000000 !important;
}
.searchResultWrapper .card
{
  max-height: 85vh;
  margin: 0px !important;
  padding: 0px !important;
}
.searchResultWrapper .container 
{
  position: relative;
  margin-top: 10px;
}
.searchWindowClose
{
  color: red;
  font-size: 30px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
  line-height: 15px;
}

@media only screen and (max-width: 600px) {
  .brandLogo {
    display: none;
  }
}
</style>
  