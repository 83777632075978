<template>
  <div class="px-0 px-md-5">
    <PrimevueSkeleton class="my-3"></PrimevueSkeleton>
    <PrimevueSkeleton height="10rem" width="100%" class="mb-2"></PrimevueSkeleton>
    <PrimevueSkeleton class="my-3"></PrimevueSkeleton>
    <PrimevueSkeleton height="10rem" width="100%" class="mb-2"></PrimevueSkeleton>
    <PrimevueSkeleton class="my-3"></PrimevueSkeleton>
    <PrimevueSkeleton height="10rem" width="100%" class="mb-2"></PrimevueSkeleton>
    <PrimevueSkeleton class="my-3"></PrimevueSkeleton>
    <PrimevueSkeleton height="10rem" width="100%" class="mb-2"></PrimevueSkeleton>
    <PrimevueSkeleton class="my-3"></PrimevueSkeleton>
    <PrimevueSkeleton height="10rem" width="100%" class="mb-2"></PrimevueSkeleton>
    <PrimevueSkeleton class="my-3"></PrimevueSkeleton>
    <PrimevueSkeleton height="10rem" width="100%" class="mb-2"></PrimevueSkeleton>
    <PrimevueSkeleton class="my-3"></PrimevueSkeleton>
    <PrimevueSkeleton height="10rem" width="100%" class="mb-2"></PrimevueSkeleton>  
    
  </div>
</template>

<script>
import PrimevueSkeleton from 'primevue/skeleton/Skeleton.vue';

export default {
  name: 'SkeletonComp',
  components:{
    PrimevueSkeleton
  }
}
</script>

<!-- Add your custom styles if needed -->
<style scoped>
.p-skeleton {
  background: linear-gradient(180deg, #8C2F23 0%, #DB3706 100%);
    /* Change this to your desired color */
}
</style>