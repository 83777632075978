<template>
    <main class="mt-5 pt-5">
        <div class="container">
            <h1 class="headline_1 mb-5 text-center">Contact us</h1>
            
            <div class="card card-body p-5">
     
                <form @submit.prevent="submitForm()" class="row g-5">
                    <div class="col-md-4">
                        <input class="form-control form-control-lg" name="name" placeholder="Name..." />
                    </div>
                    <div class="col-md-4">
                        <input class="form-control form-control-lg" name="phone" placeholder="Phone..." />
                    </div>
                    <div class="col-md-4">
                        <input class="form-control form-control-lg" name="email" placeholder="E-mail..." />
                    </div>
                    <div class="col-md-12">
                        <textarea class="form-control form-control-lg" name="text" placeholder="How can we help you?" style="height:150px;"></textarea>
                    </div>
                    <div class="col-md-12">
                        <button class="button" type="submit">Submit</button>
                    </div>
                </form>
                   
            </div>
            

        </div>
    </main>
</template>
<script>
export default{
    methods:{
        submitForm()
        {
            alert();
        }
    }
}
</script>